<template>
  <v-container fluid>
    <v-speed-dial
      value="true"
      absolute
      bottom
      right
      fab
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" fab dark color="primary">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Create resources & permissions</span>
        </v-tooltip>
      </template>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn @click="createNew.dialog = true" v-on="on" fab dark small color="darkGrey">
            <v-icon small>fas fa-tint</v-icon>
          </v-btn>
        </template>
        <span>Create a resource</span>
      </v-tooltip>
      <v-tooltip left v-if="selectedResource !== ''">
        <template v-slot:activator="{ on }">
          <v-btn @click="openPermissionsCreateDialog" v-on="on" fab dark small color="darkGrey">
            <v-icon>mdi-lock-open</v-icon>
          </v-btn>
        </template>
        <span>Create permission</span>
      </v-tooltip>
    </v-speed-dial>
    <v-row>
      <!-- Resouces Here -->
      <v-col cols="8" v-if="resources.length > 0">
        <v-treeview
          activatable
          hoverable
          selection-type="independent"
          item-text="display_name"
          :items="resources"
          return-object
          @update:active="selectResource"
        >
          <template v-slot:lable="{ item, open }">
            <v-card>{{item.display_name}}</v-card>
          </template>
          <template v-slot:append="{ item, open }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click.native.stop="createNew.parent = item, createNew.dialog = true"
                  v-on="on"
                  small
                  icon
                >
                  <v-icon small>add</v-icon>
                </v-btn>
              </template>
              <span>Add a new child resource</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn @click.native.stop="deleteAppResource(item)" v-on="on" small icon>
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
              <span>Delete resource</span>
            </v-tooltip>
          </template>
        </v-treeview>
      </v-col>
      <v-col v-else class="text-center">
        <p class="title grey--text text--darken-2 pt-10 mt-10">Create your first resource!</p>
      </v-col>
      <v-col cols="4" v-if="selectedResource !== ''">
        <!-- Permissions Here -->
        <ResourcePermissions ref="resourcePermission" :resource="selectedResource" />
      </v-col>
    </v-row>
    <v-dialog
      v-model="createNew.dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title v-if="createNew.parent === null">Create a resource</v-card-title>
        <v-card-title v-else>Create a child resource for {{createNew.parent.name}}</v-card-title>
        <v-card-subtitle>Create an app resource to manage permissions associated with it</v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="createNew.display_name"
            flat
            name="Resource Display Name"
            label="Resource Display Name"
          ></v-text-field>
          <v-text-field v-model="createNew.name" flat name="Resource Name" label="Resource Name"></v-text-field>
          <v-text-field
            v-model="createNew.description"
            flat
            name="Description"
            label="Add a description"
          ></v-text-field>
          <v-text-field
            type="number"
            v-model.number="createNew.role_permission_limit"
            flat
            name="Limit"
            label="Add a Limit"
          ></v-text-field>
          <v-checkbox class label="Customizable" v-model="createNew.is_customizable"></v-checkbox>
          <v-row v-if="createNew.is_customizable">
            <v-col class="py-0" cols="12" md="12">
              <v-select
                v-model="createNew.custom_config.data_type"
                :items="['string', 'number', 'date', 'time', 'datetime']"
                label="Type"
              ></v-select>
            </v-col>
            <v-col
              v-if="createNew.custom_config.data_type === 'number'"
              class="py-0"
              cols="12"
              md="6"
            >
              <v-text-field type="number" v-model.number="createNew.custom_config.min_value" label="Min"></v-text-field>
            </v-col>
            <v-col
              v-if="createNew.custom_config.data_type === 'number'"
              class="py-0"
              cols="12"
              md="6"
            >
              <v-text-field type="number" v-model.number="createNew.custom_config.max_value" label="Max"></v-text-field>
            </v-col>
            <v-col
              v-if="createNew.custom_config.data_type === 'number'"
              class="py-0"
              cols="12"
              md="6"
            >
              <v-text-field type="number" v-model.number="createNew.custom_config.min_value" label="Min"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="createNew.dialog = false, createNew.parent = null"
            color="grey"
            text
            class="text-capitalize"
          >Cancel</v-btn>
          <v-btn
            elevation="0"
            @click="createResource"
            color="primary"
            class="text-capitalize"
          >Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Create New Button -->
    <!-- DELETE DIALOG -->
    <DeleteModal
      :title="deleteDialog.title"
      :status="deleteDialog.status"
      :callback="deleteDialog.callback"
      :description="deleteDialog.description"
      @success="getApplicationResources(); deleteDialog.status = false"
      @close="deleteDialog.status = false"
    />
  </v-container>
</template>

<script>
import { ApplicationsAPI } from '@/clients/permissions/apps.js'

export default {

  mounted() {
    this.getApplicationResources()
  },
  data() {
    return {
      resources: [],
      selectedResource: '',
      newPermission: {
        display_name: 'Permission name',
        description: 'Add a description...',
        createNew: false
      },
      createNew: {
        dialog: false,
        name: '',
        display_name: '',
        description: '',
        role_permission_limit: '',
        is_customizable: false,
        custom_config: {
          data_type: 'string',
          min_value: 0,
          max_value: 0
        },
        parent: null
      },
      deleteDialog: {
        status: false,
        title: '',
        description: `Are you sure you want to delete this resource? This will permanently delete this and all the child resources associated with it.
        Proceed?`,
        callback: null,
        permissionId: null
      }
    }
  },
  components: {
    ResourcePermissions: () => import('./Permissions'),
    DeleteModal: () => import('@/components/custom-components/DeleteModal')
  },
  methods: {
    openPermissionsCreateDialog() {
      this.$refs['resourcePermission'].createNew.dialog = true
    },
    async getApplicationResources() {
      this.spinner(true)
      let response
      try {
        response = await ApplicationsAPI.getAllResourcesForAnApp(this.$route.params['id'])
      } catch (error) {
        this.spinner(false)
        console.log(error)
        return
      }
      this.resources = response.data.payload
      this.spinner(false)
    },
    selectResource(resource) {
      let self = this
      this.selectedResource = ''
      if (resource.length === 1) {
        this.$nextTick(() => {
          self.selectedResource = resource[0]
        })
      } else {
        this.$nextTick(() => {
          self.selectedResource = ''
        })
      }
    },
    async createResource() {
      this.spinner(true)
      let data = {
        name: this.createNew.name,
        display_name: this.createNew.display_name,
        description: this.createNew.description,
        role_permission_limit: this.createNew.role_permission_limit,
        is_customizable: this.createNew.is_customizable,
        custom_config: this.createNew.custom_config
      }
      if (this.createNew.parent !== null) {
        data.parent_id = this.createNew.parent.id
      }
      try {
        await ApplicationsAPI.createApplicationResource(this.$route.params['id'], data)
      } catch (error) {
        this.spinner(false)
        console.log(error)
        return
      }
      this.createNew = {
        dialog: false,
        name: '',
        display_name: '',
        description: '',
        parent: null,
        is_customizable: false,
        role_permission_limit: '',
        custom_config: {
          data_type: 'string',
          min_value: 0,
          max_value: 0
        }
      }
      await this.getApplicationResources()
      this.spinner(false)
    },
    deleteAppResource(resource) {
      this.deleteDialog.status = true
      this.deleteDialog.title = `Are you sure you want to delete ${resource.display_name}?`
      this.deleteDialog.callback = () => ApplicationsAPI.deleteAnApplicationResource(this.$route.params['id'], resource.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-treeview-node__root:hover {
  background: red !important;
}
</style>
